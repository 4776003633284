import React from "react"

/* Import Global Components */
import Page from "~components/page"

const PageNotFound = () => (
  <Page title="404">
    <div>404</div>
  </Page>
)

export default PageNotFound
